import {
  mdiLoading,
  mdiPlusCircle,
  mdiArrowRight,
  mdiAccountCog,
  mdiFileDocumentEditOutline,
  mdiKeyboardReturn,
  mdiDnsOutline,
  mdiAccountEdit,
  mdiChevronLeft,
  mdiLogout,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiClock,
  mdiPin,
  mdiLinkVariant,
  mdiFolderOpen,
  mdiAccountPlus,
  mdiAccount,
  mdiMenuRightOutline,
  mdiReply,
  mdiCloseCircle,
  mdiMenuLeftOutline,
  mdiMagnify,
  mdiSortAlphabeticalVariant,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiUnfoldMoreHorizontal,
  mdiInformationOutline,
  mdiAlertCircleOutline,
  mdiChartAreaspline,
  mdiHistory,
  mdiCheckCircle,
  mdiFilter,
  mdiCheckAll,
  mdiTrashCanOutline,
  mdiPencil,
  mdiCheck,
  mdiRadar,
  mdiCalendarMonth,
  mdiArrowExpand,
  mdiEye,
  mdiAccountGroup,
  mdiHammerWrench,
  mdiAlert,
  mdiInformation,
  mdiBell,
  mdiCog,
  mdiArrowLeft,
  mdiChartBar,
  mdiAlertRhombus,
  mdiChevronDoubleRight,
  mdiDragHorizontalVariant,
  mdiChevronDoubleLeft,
  mdiPlus,
  mdiTrashCan,
  mdiArrowDown,
  mdiArrowUp,
  mdiMenuUp,
  mdiMenuDown,
  mdiTrayArrowDown,
  mdiArrowUpCircle,
  mdiArrowDownCircle,
  mdiPlaylistCheck,
  mdiAccountDetails,
  mdiExport,
  mdiMicrophoneOff,
  mdiMicrophone,
  mdiMinusCircle,
  mdiRefresh,
} from '@mdi/js';

export default {
  mdiMicrophoneOff,
  mdiExport,
  mdiAccount,
  mdiMenuRightOutline,
  mdiMenuLeftOutline,
  mdiLoading,
  mdiKeyboardReturn,
  mdiFileDocumentEditOutline,
  mdiDnsOutline,
  mdiCalendarMonth,
  mdiPlus,
  mdiRadar,
  mdiAccountEdit,
  mdiPlusCircle,
  mdiArrowRight,
  mdiAccountGroup,
  mdiAccountDetails,
  mdiAccountCog,
  mdiChevronLeft,
  mdiLogout,
  mdiChevronDown,
  mdiChevronUp,
  mdiReply,
  mdiClose,
  mdiAccountPlus,
  mdiClock,
  mdiPin,
  mdiLinkVariant,
  mdiMagnify,
  mdiSortAlphabeticalVariant,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiUnfoldMoreHorizontal,
  mdiInformationOutline,
  mdiAlertCircleOutline,
  mdiChartAreaspline,
  mdiHistory,
  mdiCheckCircle,
  mdiFilter,
  mdiTrashCanOutline,
  mdiPencil,
  mdiCheck,
  mdiEye,
  mdiHammerWrench,
  mdiAlert,
  mdiArrowExpand,
  mdiInformation,
  mdiBell,
  mdiCog,
  mdiArrowLeft,
  mdiCheckAll,
  mdiAlertRhombus,
  mdiChartBar,
  mdiDragHorizontalVariant,
  mdiChevronDoubleRight,
  mdiChevronDoubleLeft,
  mdiTrashCan,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuUp,
  mdiCloseCircle,
  mdiFolderOpen,
  mdiMenuDown,
  mdiTrayArrowDown,
  mdiArrowUpCircle,
  mdiArrowDownCircle,
  mdiPlaylistCheck,
  mdiMicrophone,
  mdiMinusCircle,
  mdiRefresh,
};
